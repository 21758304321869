<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">


              <!-- <button
                    class="btn btn-sm btn-success mr-2 ml-2"
                  >
                    <CIcon name="cil-spreadsheet" />
                    Export Excel
                </button>
                <button class="btn btn-sm btn-danger">
                  <CIcon name="cib-adobe-acrobat-reader" />
                  Export PDF
                </button> -->

              <!-- <label class="m-1 ml-3" for="">Select All : </label>
              <input type="checkbox" v-model="isSelectedAll" @change="checkAll()" />

              <select v-if="selectedItems.length > 0" style="max-width: 200px" class="form-control form-control-sm mx-2"
                placeholder="Ketik disini" v-model="selectedAction" @change="changeActionSelected()">
                <option value="0">Action Selected</option>
                <option value="1">Delete Items Selected</option>
                <option value="2">Export Excel Items Selected</option>
                <option value="3">Export Pdf Items Selected</option>
              </select> -->

              <CButton class="mx-3" @click="exportExcel" color="success">Export Excel</CButton>

            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <!-- <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 175px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button> -->

              <input style="max-width: 150px" class="form-control form-control-sm mx-3" type="number" @change="getData"
                v-model="params.year" placeholder="Tahun" min="2000" max="2050">
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
              </span>
            </h3>
          </div>
        </div>
        <!-- start table -->
        <CDataTable 
          class="table-striped" 
          :items="computedItems" 
          :fields="fields" 
          sorter>
          <template #select="{ item }">
            <td class="py-2">
              <input type="checkbox" @change="check(item)" v-model="item.select" />
            </td>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton @click="edit(item)" class="mr-2" color="warning" square size="sm">
                Edit
              </CButton>
              <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination v-if="total > 5" v-model="page" :records="total" :per-page="5" @paginate="pagination" />
      </CCardBody>
    </CCard>
    <!-- Import modal -->
    <!-- <CModal size="lg" title="Import Data" centered color="primary" :show.sync="createModalImport">
      <div class="row">
        <div class="col">
          <div class="div">
            <input type="file" class="form-control" ref="importData" @change="importData" />
          </div>
        </div>
      </div>
    </CModal> -->
  </div>
</template>
  
  
  
<script>
// import * as data from "../../model/list-produksi";
// import { uploadImage } from "@/utils/fileUpload";
import kecamatan, { desa } from "@/utils/internalData";
import FileSaver from "file-saver";


export default {
  data() {
    return {
      desa,
      kecamatan,
      createModal: false,
      createModalImport: false,
      modalFields: [],
      fields: [],
      isUpdate: false,
      items: [],
      tahun: "",
      imageListAfter: [],
      imageListProcess: [],
      imageListBefore: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      selectedDesa: "",
      jenisHewan: [],
      listKelompok: [],
      selectedKecamatan: "",
      selectedJenisHewan: "",
      page: 1,
      total: 0,
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        year: new Date().getFullYear()
      },
      isSearching: false,
      searchOn: '',
      listParameter: [],
      listPopulasi: []
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      // console.log(this.params.tahun)
      this.items = []
      this.$store
        .dispatch("list_produksi/getListProduksi", this.params)
        .then((resp) => {
          // console.log(resp)
          this.items = resp;
          
          const first = [
            { key: 'index', label: "No", _style: 'min-width:50px' },
            { key: 'bulan', label: "Bulan", _style: 'min-width:100px;' },
          ]
          const second = []
        
          for (const item in resp.data.Januari) {
            second.push(item.toLowerCase().split(" ").join("_"))
          }

          second.map((item) => {
            first.push({ key: item, label: item.toUpperCase().split("_").join(" "), _style: 'min-width:150px;' })
          })

          this.fields = first

          loading.hide();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    addModal() {
      this.isUpdate = false;
      this.createModal = true;
    },
    addModalImport() {
      this.createModalImport = true;
    },
    
    check(item) {
      if (item.select) {
        this.selectedItems.push(item.id);
      } else {
        const index = this.selectedItems.indexOf(item.id);
        this.selectedItems.splice(index, 1);
      }
      // console.log(item)
    },
    checkAll() {
      this.getData();
    },
    changeActionSelected() {
      console.log(this.selectedAction)
      switch (Number(this.selectedAction)) {
        case 1:
          console.log('deleted')
          this.deleteSelected('delete');
          break;
        case 2:
          console.log('export excel')
          this.exportExcel('export_excel');
          break;
        case 3:
          console.log('export pdf')
          this.exportPDF();
          break;
      }
    },
    deleteSelected(action) {
      var loading = this.$loading.show();
      this.$store
        .dispatch("list_produksi/selectedAction",
          {
            action: action,
            data: this.selectedItems,
          })
        .then(() => {
          this.$toast.success("Item Selected Berhasil diproses");
          loading.hide();
          this.createModal = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    openModalExcel() {
      this.exportModal = true;
      this.exportType = "Export Excel";
    },
    openModalPDF() {
      this.exportModal = true;
      this.exportType = "Export PDF";
    },
    exportExcel() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("list_produksi/exportReport", {
          data: this.selectedItems,
        })
        .then((resp) => {
          loading.hide();
          FileSaver.saveAs(
            resp.data,
            "Laporan_Harian_"
          );
          this.exportModal = false;
          this.exportDataParams = {};
        })
        .catch((e) => {
          this.$toast.error(e);
          // console.log(e)
          loading.hide();
        });
    },
    exportPDF() {
      this.$router.push({ name: "ExportProduksiPdf", query: { fields: this.computedFields, items: this.computedItems } });
      // console.log(this.computedItems)
    },
  },
  computed: {
    computedItems() {
      // table content
      const first = []
      const fixItems = []
      let i = 0
      for (const item in this.items.data) {
        // console.log(item)
        i++
        
        first.push({
          index: i,
          bulan: item,
          ...this.items.data[item]
        })
        // console.log(first)
      }
      first.map((item) => {
        const newObject = {};

        Object.keys(item).forEach(key => {
          newObject[key.toLowerCase().split(" ").join("_")] = item[key];
        });

        fixItems.push(newObject)
      })

      // console.log(fixItems)
      return fixItems
    },
    
  },
  // mounted() {
  //   this.getData();
  //   // this.getListJenisHewan()
  // },

  created() {
    this.user.role.name.toLowerCase() == "admin" ? this.getData() : "";
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}
</style>